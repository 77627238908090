import Vue from 'vue'

// Register a global custom directive called `v-fade-on-load`
Vue.directive('fade-on-load', {
    bind: (el: HTMLElement) => {
        setTimeout(() => { // cache isn't trigered until after initial JS execution
            if (el instanceof HTMLImageElement && !el.complete) {
                el.style.opacity = "0"
                el.style.transition = "opacity 0.3s ease"
                el.onload = () =>(el.style.opacity = "1")
            }
        })
    }
})