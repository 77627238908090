import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Store } from 'vuex/types'

export default ({ $axios, store }: { $axios: NuxtAxiosInstance, store: Store<any> }) => {

    // https://stackoverflow.com/a/37563868
    const iso8601Date = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

    $axios.interceptors.request.use((config) => {

        // Set Bearer authentication header automatically
        // https://github.com/nuxt-community/modules/issues/89#issuecomment-318812836
        const token = store.state.token
        if (token)
            config.headers.common['Authorization'] = `Bearer ${token}`

        // TODO: add a secret to the proxy so that the backend may only accept requests coming from there

        return config

    }, (error) => {
        
        // TODO: Show the error to the client
        
        return Promise.reject(error)
    })

    $axios.interceptors.response.use((response) => {

        // Automatically convert anything that has the date format to a date
        // TODO: this is inneficient and dangerous. Find a better way to automate this
        const autoParseDate = (obj: any): any => {
            if (typeof obj === "string" && obj.match(iso8601Date)) {
                return new Date(obj)
            } else if (Array.isArray(obj)) {
                return obj.map(e => autoParseDate(e))
            } else if (typeof obj === "object" && obj !== null) {
                Object.entries(obj).forEach(([key, value]) => obj[key] = autoParseDate(value))
            }
            return obj
        }

        response.data = autoParseDate(response.data)

        return response

    }, (error) => Promise.reject(error))

}