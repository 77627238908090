import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'

@Component
export default class EquiamLogo extends Vue {
  @Prop({ default: '#002e6d' }) primaryColor!: string
  @Prop({ default: '#999999' }) secondaryColor!: string
}

Vue.component('equiam-logo', EquiamLogo)
