import numeral from 'numeral'
import Vue from 'vue'
import { Component, Model, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class FormattedNumberField extends Vue {
  @Prop() @Model('input') value!: string
  @Prop() mask!: string
  @Prop() prefix!: string
  @Prop() label!: string
  @Prop() hint?: string
  @Prop() rules?: any
  @Prop() persistentHint?: boolean
  @Prop() hideDetails?: boolean
  @Prop() errorMessages?: string[]
  @Prop() maxlength?: number
  @Prop({ default: 0 }) precision!: number
  @Prop() autofocus!: boolean
  @Prop() disabled!: boolean
  model: string | null = null
  isFocused = false

  created() {
    this.onBlur()
  }

  onInput(value: string) {
    this.model = value
    this.$emit('input', this.parseInput(value))
    this.$emit('rawinput', value)
  }

  parseInput(value: string | null) {
    if (value === null || value === undefined) {
      return null
    }
    const numString = (value + '').replace(/[^\d\.]/g, '')
    try {
      const num =
        Math.round(parseFloat(numString) * Math.pow(10, this.precision || 0)) / Math.pow(10, this.precision || 0)
      return isNaN(num) ? null : num
    } catch (e) {
      // ignore this
      return null
    }
  }

  onBlur() {
    this.isFocused = false
    this.format()
  }

  @Watch('value', { immediate: true })
  format() {
    if (!this.isFocused) {
      const notSet = this.value === null || this.value === '' || typeof this.value === "undefined"
      this.model = notSet ? '': numeral(this.parseInput(this.value)).format(this.mask)
      this.$emit('rawinput', this.model)
    }
  }

  onFocus() {
    if (!this.parseInput(this.value)) {
      this.model = ''
    }
    this.isFocused = true
    this.$emit('focus')
  }
}

Vue.component('formatted-number-field', FormattedNumberField)
