import { MutationTree } from 'vuex'

export const state: () => RootState = () => ({
    isNavMenuOpened: false
})

type RootState = {
    isNavMenuOpened: boolean
}
  
export const mutations: MutationTree<RootState> = {
    setNavMenuOpened (state: RootState, isOpen = true) {
        state.isNavMenuOpened = isOpen
    }
}