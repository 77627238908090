import { Routes } from '~/components/Routes'
import { Context } from '@nuxt/types'

export default ({ store, redirect, route }: Context) => {
    const routes = new Routes()
    if (!store.state.session) {
      return redirect(routes.logInWithRedirect(route.fullPath))
    }  else if (store.state.session.capabilities.indexOf('admin') === -1) {
      return redirect(routes.home)
    }
  }