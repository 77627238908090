import { MetaInfo } from 'vue-meta'
import Vue from 'vue'
import { ISession } from '~/backend/ApiTypes'
import numeral from 'numeral'

export const Util = {
  offsetLeft(obj: HTMLElement) {
    if ('getBoundingClientRect' in document.documentElement) {
      return window.pageXOffset + obj.getBoundingClientRect().left
    }
    let curleft = 0
    while (obj.offsetParent) {
      curleft += obj.offsetLeft
      obj = obj.offsetParent as HTMLElement
    }
    return curleft
  },
  metaFields: (options: { title: string; description?: string, meta?: any[] }): MetaInfo => {
    let meta = [
      { hid: 'twitter:title', name: 'twitter:title', content: options.title },
      { hid: 'og:title', property: 'og:title', content: options.title },
    ]
    if (options.description) {
      options.description = options.description.replace(/\s+/g, ' ')
      meta = meta.concat([
        { hid: 'description', name: 'description', content: options.description },
        { hid: 'twitter:description', name: 'twitter:description', content: options.description },
        { hid: 'og:description', property: 'og:description', content: options.description },
      ])
    }
    return {
      title: options.title,
      meta: [...meta, ...(options.meta || [])],
    }
  },
  areCookiesEnabled(vue: Vue) {
    const cookieName = '----equiam-temp----'
    const cookieValue = 'cookies-activated'
    vue.$cookies.set(cookieName, cookieValue)
    const res = vue.$cookies.get(cookieName) === cookieValue
    vue.$cookies.remove(cookieName)
    return res
  },
  // This does not belong here. Perhaps Vuex can get the cookie for us
  async updateVuexPostLogin(vue: Vue, session?: ISession) {
    const token = vue.$cookies.get('_sessiondata')
    await vue.$store.commit('setUserInformation', { session, token })
  },
  isPropTruthy: (prop: any) => typeof prop !== "undefined" && prop !== false,
  formatInteger(num: number, significantDigits: number) {
    return numeral(num.toPrecision(significantDigits))
      .format(`0,0[.][${new Array(significantDigits).join('0')}]a`)
      .toUpperCase()
  },
  initiateDownload: (url: string) => new Promise((resolve) => {
    const iframe = document.createElement('iframe')
    iframe.onload = () => {
      setTimeout(() => iframe.remove(), 1000)
      resolve()
    }
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    iframe.src = url
  }),
  // https://stackoverflow.com/a/2117523
  generateUuid: () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }),
}
