import { Analytics } from '~/components/Analytics'

export default async ({ app }: any) => {

  app.router.afterEach((to: any, from: any) => {
    Analytics.page(to.path || '', {
      path: to.fullPath,
      referrer: from.fullPath,
    })
  });
  
}