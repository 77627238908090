// Enforce a 100ms delay between each tracking event, since it seems that
// ActiveCampaign has a tough time determining the order of events, 
// especially after the alias event
const queue: any[] = []
let timerSetAt: number = 0
const executeOrEnqueue = <T extends Array<any>, U>(fn: (...args: T) => U) => 
  (...args: T): void => { process.client && (queue.push({ fn, args }), emptyQueue()) }

const emptyQueue = () => {
  if (window.analytics) {
    const { fn, args } = queue.shift()
    fn(...args)
  }
  if (timerSetAt + 500 <= new Date().getTime() && queue.length) {
    timerSetAt = new Date().getTime()
    setTimeout(emptyQueue, 100)
  }
}

export const Analytics = {
  track: executeOrEnqueue((eventName: string | number, obj?: any) => window.analytics.track(eventName, obj)),
  page: executeOrEnqueue((pathname: string | number, obj?: any) => window.analytics.page(pathname, obj)),
  identify: executeOrEnqueue((userId: string | number, tags?: any) => window.analytics.identify(userId, tags)),
  reset: executeOrEnqueue(() => window.analytics.reset()),
}
