import { MutationTree, ActionTree } from 'vuex'
import jwtDecode from 'jwt-decode'
import { ISession } from 'backend/ApiTypes'

export const state: () => RootState = () => ({
    token: null,
    session: null
})

type RootState = {
    token: string | null
    session: ISession | null
}
  
export const mutations: MutationTree<RootState> = {
    setUserInformation (state: RootState, data: { session: ISession, token: string }) {
        state.session = data.session
        state.token = data.token
    },
    logOut (state: RootState) {
        state.session = null
        state.token = null
    }
}

export const actions: ActionTree<RootState, RootState> = {
    async nuxtServerInit ({ commit }, { app }) {
        // TODO: Minor security flaw: the JWT's signature isn't checked.
        // We'll let this one be as the front-end doesn't have access to any resource worthy of protection
        // If anything, a user will be able to view portfolio composition, but this is also visible by looking at the source code
        // The backend does a proper validation of the JWT
        const token = app.$cookies.get('_sessiondata')
        if (token) {
            const jwt = jwtDecode(token) as { data: ISession, exp: number }
            if (jwt.exp < new Date().getTime() / 1000)
                console.info("JWT has expired", jwt.exp)
            else
                await commit('setUserInformation', { session: jwt.data, token })
        }
    }
}