import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

@Component
export class ScrollListener extends Vue {
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    // dummy method
  }
}
