import Vue from 'vue'
import { Component, Inject } from 'nuxt-property-decorator'
import { Routes } from '~/components/Routes'
import { Util } from '~/components/Util'
import { AxiosResponse } from 'axios'
import { ILogInResult } from '~/backend/ApiTypes'

@Component
export default class ShadowingIndicator extends Vue {
    @Inject() routes!: Routes
    loading = false

    endShadowingSession() {
        if (!this.loading) {
            this.loading = true
            this.$axios.post('/auth/shadowing/stop')
            .then(async ({data}: AxiosResponse<ILogInResult>) => {
                await Util.updateVuexPostLogin(this, data.session)
                this.$store.commit('snacks/emit', { text: 'Your shadowing session has ended', color: 'success' })
                this.$router.push(this.routes.admin)
            })
            .catch(e => this.$store.commit('snacks/error', e))
            .finally(() => this.loading = false)
        }
    }
}

Vue.component('shadowing-indicator', ShadowingIndicator)
