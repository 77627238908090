import { MutationTree } from 'vuex'

// TODO: support stacked snacks
export const state: () => RootState = () => ({
    text: '',
    color: '',
    show: false,
    persistent: false
})

type RootState = {
    text: string
    color: string | null
    show: boolean
    persistent: boolean
}
  
export const mutations: MutationTree<RootState> = {
    emit (state: RootState, data: { text: string, color?: string | null, persistent?: boolean } | string) {
        if (typeof data === 'string') {
        data = { text: data }
        }
        state.text = data.text
        state.color = data.color || null
        state.persistent = data.persistent || false
        state.show = true
    },
    error (state: RootState, text: string) {
        state.text = text
        state.color = 'error'
        state.persistent = false
        state.show = true
    },
    hide (state: RootState) {
        state.text = ''
        state.color = null
        state.persistent = false
        state.show = false
    }
}