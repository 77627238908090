import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

@Component
export class ResizeListener extends Vue {
  mounted() {
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    // dummy method
  }
}
