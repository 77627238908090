import numeral from 'numeral'
import Vue from 'vue'
import { Component, Model, Prop } from 'vue-property-decorator'

@Component
export default class FormattedCash extends Vue {
    @Prop() value!: number

    get dollars() {
        return numeral(Math.floor(this.value)).format('$0,0')
    }

    get cents() {
        return numeral(this.value).format('.00')
    }
}

Vue.component('formatted-cash', FormattedCash)
