import isEmail from 'validator/lib/isEmail'

export type Validator = (val: any) => true | string

export class Validation {
  nonEmpty (val: any) {
    return (
      !(val === undefined || val == null || (typeof val === 'string' && val.trim() === '')) || 'This field is required'
    )
  }
  email (val: string) {
    return isEmail(val) || 'Your email address is not valid'
  }
  password(val: string) {
    return (typeof val === 'string' && val.length >= 6) || 'Passwords must be longer than 5 characters'
  }
}
