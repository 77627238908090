export const Intercom = {
  inject: () => {
    window.intercomSettings = {
      app_id: process.env.ENVIRONMENT === 'production' ? 'zrwlvwwz' : 'h7bi8stb'
    }
    ;(function () {
      const w = window
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', window.intercomSettings)
      } else {
        const d = document
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + window.intercomSettings.app_id
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
    })()
  }
}
