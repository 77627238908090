export class Routes {
  readonly home = '/'
  readonly logIn = '/log-in'
  readonly twoFactorAuth = '/log-in/2fa'
  readonly forgotPassword = '/forgot-password'
  readonly investorPortal = '/'
  readonly investorPortalDocuments = '/documents'
  readonly investorPortalFinancials = '/financials'
  readonly admin = '/admin'
  readonly loggedOutScreen = 'https://equiam.com'

  readonly logInWithRedirect = (redirect: string) => '/log-in?redirect=' + encodeURIComponent(redirect)
  readonly faqWithQuestion = (questionId: string) => '/faq/' + questionId
  readonly portfolioDetailsForFund = (accountName: string, fundName: string) =>
    this.investorPortal +
    accountName.toLowerCase().replace(/[^a-z0-9]+/g, '-') +
    '/' +
    fundName.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}
