import { Util } from '~/components/Util'
import Vue from 'vue'
import { Component, Model, Prop } from 'nuxt-property-decorator'

export interface Item {
  text: string
  value: string
}

@Component
export default class MultilineButtonToggle extends Vue {
  @Prop() items!: Item[]
  @Prop() multiple!: boolean
  @Prop() rules!: Array<(val: any) => boolean | string>
  @Prop() errorMessages!: string[]
  @Prop() disabled!: boolean
  @Prop() @Model('input') selectedItems!: string[] | string

  onChange(value: number | number[]) {
    if (typeof value === "number") {
      this.$emit('input', this.items[value].value)
    } else if (Array.isArray(value)) {
      this.$emit('input', value.map(v => this.items[v].value))
    } else {
      this.$emit('input', Util.isPropTruthy(this.multiple) ? []: null)
    }
  }

  get selectedIndex() {
    const itemsValue = this.items.map(i => i.value)
    if (Array.isArray(this.selectedItems)) {
      return this.selectedItems.map(s => itemsValue.indexOf(s))
    } return itemsValue.indexOf(this.selectedItems)
  }

}

Vue.component('multiline-button-toggle', MultilineButtonToggle)
