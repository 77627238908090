<template>
  <v-layout class="wrapper equiam-tile" justify-center align-center wrap>
    <v-layout class="content" column justify-center align-center>
      <h1 class="lato">
        Oops. There is nothing here.
      </h1>
      <p class="lato">
        <span style="font-weight: 500">EQUIAM</span> applies a rigorous, systematic investment approach to private
        capital markets.
      </p>
      <v-btn color="teal" :to="routes.home">
        Go to home page
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script lang="typescript">
import '~/components/atoms/EquiamLogo.vue'

export default {
  props: ['error'],
  inject: ['routes']
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 30px;
  color: #799fde;
  text-align: center;
}
.wrapper {
  background: #1d3660;
  min-height: 100vh;
  padding-bottom: 80px;
}
.content {
  max-width: 500px;
  margin: auto;
  padding: 15px;
  position: relative;
}
p {
  font-size: 20px;
  color: #799fde;
  padding: 10px 0 30px;
  text-align: center;
}
</style>
