import Vue from 'vue'
import { Component, Model, Prop, Watch } from 'nuxt-property-decorator'
import { ICountryType } from 'backend/ApiTypes'
import { AxiosResponse } from 'axios';

@Component
export default class CountryPicker extends Vue {
  @Prop() @Model('input') value!: string | number | null
  @Prop() initialCountry!: ICountryType
  @Prop() errorMessages!: string[]
  @Prop() rules!: Array<(val: any) => boolean | string>
  @Prop() required!: boolean
  @Prop() hideDetails!: boolean
  countries: ICountryType[] = []
  loading = false
  searchQuery = ''

  @Watch('initialCountry', { immediate: true })
  onInitialCountryChange() {
    if (this.initialCountry) {
      this.countries = [this.initialCountry]
      this.loading = false
    } else {
      this.onSearch('')
    }
  }

  @Watch('searchQuery')
  onSearch(searchQuery: string) {
    this.searchFor((searchQuery || '').toLowerCase())
  }

  searchFor(search: string) {
    this.loading = true
    this.$axios.get('/countries', { params: { search } })
        .then(({data}: AxiosResponse<ICountryType[]>) => this.countries = data)
        .finally(() => (this.loading = false))
  }

  focus() {
    const select = this.$refs.select as Vue
    ;(select.$el as HTMLElement).getElementsByTagName('input')[0].focus()
  }

  setCountry(identifier: string | number | null) {
    if (identifier === null || ['string', 'number'].indexOf(typeof identifier) !== -1) {
      this.$emit('input', identifier)
      this.$emit('country', this.countries.filter(e => e.countryCode2 === identifier)[0])
    }
  }
}

Vue.component('country-picker', CountryPicker)
