import { render, staticRenderFns } from "./FormattedCash.vue?vue&type=template&id=0d8b0013&scoped=true&"
import script from "./FormattedCash.ts?vue&type=script&lang=typescript&"
export * from "./FormattedCash.ts?vue&type=script&lang=typescript&"
import style0 from "./FormattedCash.vue?vue&type=style&index=0&id=0d8b0013&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8b0013",
  null
  
)

export default component.exports