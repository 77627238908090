export const LogRocket = {
  inject: () => {
    const script = document.createElement('script')
    script.crossorigin = 'anonymous'
    script.src = 'https://cdn.lr-ingest.io/LogRocket.min.js'

    script.onload = function () {
      const logRocketToken = 'equiam/platform'
      window.LogRocket && window.LogRocket.init(logRocketToken)
    }
    document.head.appendChild(script)
  }
}
