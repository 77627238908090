import Vue from 'vue'
import '~/components/atoms/inputs/CountryPicker.vue'
import '~/components/atoms/EquiamLogo.vue'
import '~/components/atoms/inputs/FormattedNumberField.vue'
import '~/components/atoms/MultilineButtonToggle.vue'
import '~/components/ShadowingIndicator.vue'
import '~/components/atoms/FormattedCash'
import { Validation } from '~/components/Validation'
import Component from 'vue-class-component'
import { Provide, Watch, mixins } from 'nuxt-property-decorator'
import { Routes } from '~/components/Routes'
import { ResizeListener } from '~/components/mixins/ResizeListener'
import { ScrollListener } from '~/components/mixins/ScrollListener'
import { Segment } from '~/components/third-parties/Segment'
import { LogRocket } from '~/components/third-parties/LogRocket'
import { Intercom } from '~/components/third-parties/Intercom'
import { Util } from '~/components/Util'

process.client && console.info(`Version at commit ${process.env.COMMIT_HASH}.`)

@Component({
  head: Util.metaFields({
    title: 'EQUIAM',
    description:
      'EQUIAM believes there is a better way to invest in private markets. Our systematic, data-driven process allows to rapidly identify exceptional, rigorously-vetted private firms.',
    meta: [
      { name: 'application-name', content: 'EQUIAM' },
      { name: 'apple-mobile-web-app-title', content: 'EQUIAM' },
      { name: 'theme-color', content: '#1d3660' },
      { name: 'msapplication-navbutton-color', content: '#1d3660' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#1d3660' },
      { name: 'og:site_name', content: 'EQUIAM' },
      { name: 'og:url', content: 'https://equiam.com' },
      { name: 'og:type', content: 'website' },
      { name: 'og:image', content: 'https://equiam.com/thumbnail.png' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:url', content: 'https://equiam.com' },
      { name: 'twitter:image', content: 'https://equiam.com/thumbnail.png' },
    ],
  }),
})
export default class Default extends mixins(ResizeListener, ScrollListener) {
  @Provide() routes = new Routes()
  @Provide() validation = new Validation()
  slideoutOpen = false
  scrollAtOrigin = true

  created() {
    // Add context information from Vuex to Sentry exceptions
    this.$sentry &&
      this.$sentry.configureScope((scope: any) => {
        const session = this.$store.state.session
        session &&
          scope.setExtra({
            exceptionId: Util.generateUuid(),
            session,
          })
      })

    this.$router.afterEach(this.closeSlideout)
  }

  onResize() {
    if (window.innerWidth > 650) {
      this.closeSlideout()
    }
  }

  closeSlideout() {
    this.$store.commit('misc/setNavMenuOpened', false)
  }

  get windowWidth() {
    return process.browser ? window.innerWidth : 500
  }

  mounted() {
    if (process.env.ENVIRONMENT === 'production' && 'equiam.com'.includes(window.location.hostname)) {
      Segment.inject()
      LogRocket.inject()
    }
    Intercom.inject()
  }

  contentComponent() {
    return (this.$refs.nuxtContent as any).$children[0]
  }
}
