import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61971fa3 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _3221ff99 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _2a582d10 = () => interopDefault(import('../pages/log-in/index.vue' /* webpackChunkName: "pages/log-in/index" */))
const _02a3415e = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _7d1bd928 = () => interopDefault(import('../pages/set-password/index.vue' /* webpackChunkName: "pages/set-password/index" */))
const _3d7d2b62 = () => interopDefault(import('../pages/shadow/index.vue' /* webpackChunkName: "pages/shadow/index" */))
const _ebe6c5ba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _167a664c = () => interopDefault(import('../pages/index/documents.vue' /* webpackChunkName: "pages/index/documents" */))
const _673df526 = () => interopDefault(import('../pages/index/financials.vue' /* webpackChunkName: "pages/index/financials" */))
const _19f57616 = () => interopDefault(import('../pages/index/_accountName/_fundName.vue' /* webpackChunkName: "pages/index/_accountName/_fundName" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _61971fa3,
    name: "admin"
  }, {
    path: "/forgot-password",
    component: _3221ff99,
    name: "forgot-password"
  }, {
    path: "/log-in",
    component: _2a582d10,
    name: "log-in"
  }, {
    path: "/redirect",
    component: _02a3415e,
    name: "redirect"
  }, {
    path: "/set-password",
    component: _7d1bd928,
    name: "set-password"
  }, {
    path: "/shadow",
    component: _3d7d2b62,
    name: "shadow"
  }, {
    path: "/",
    component: _ebe6c5ba,
    name: "index",
    children: [{
      path: "documents",
      component: _167a664c,
      name: "index-documents"
    }, {
      path: "financials",
      component: _673df526,
      name: "index-financials"
    }, {
      path: ":accountName?/:fundName?",
      component: _19f57616,
      name: "index-accountName-fundName"
    }]
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
