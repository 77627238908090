const middleware = {}

middleware['admin'] = require('../middleware/admin.ts')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['unauthenticated'] = require('../middleware/unauthenticated.ts')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
